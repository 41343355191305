import { Temporal } from '@js-temporal/polyfill';
import { PortableTextComponentProps } from '@portabletext/react';

import { RoutineComponent } from '../views/RoutineComponent';
import CMSBlock from './CMSBlock';

type CMSRoutineBlockProps = PortableTextComponentProps<{
	_type: 'Routine';
	variant?: 'default' | 'withFooter';
	checkInText?: string;
}>;

const CMSRoutineBlock = ({ value }: CMSRoutineBlockProps) => {
	const today = Temporal.Now.plainDateISO();
	const weekDays = Array.from({ length: 7 }, (_, i) => {
		const date = today.add({ days: i });
		return {
			name: date.toLocaleString('en-US', { weekday: 'long' }),
			isChecked: value.variant === 'withFooter',
		};
	});

	const nextCheckInDate = today
		.add({ days: 7 })
		.toLocaleString('en-US', { weekday: 'long' });

	return (
		<CMSBlock textAlign="auto">
			<RoutineComponent
				weekDays={weekDays}
				variant={value.variant}
				nextCheckInDate={nextCheckInDate}
				checkInText={value.checkInText}
			/>
		</CMSBlock>
	);
};

export default CMSRoutineBlock;
