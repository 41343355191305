import { SQScorePillar, sqScorePillarsOrdered } from '@wearemojo/sanity-schema';
import { themeColors, UITheme, UIThemeColors } from '@wearemojo/ui-constants';
import { ImageSourcePropType } from 'react-native';

import { INITIAL_DOT_PROGRESS } from './timer';
import { SegmentedProgressProps } from './types';

export type SQPillarScore = {
	pillar: SQScorePillar;
	value: number; // 0-25 integer
};

export const sqScoreMaxOverallValue = 100;
export const sqScoreMaxPillarValue = Math.floor(
	sqScoreMaxOverallValue / sqScorePillarsOrdered.length,
);

export const sqScoreThemeColors = {
	[SQScorePillar.sexualFitness]: 'fill_score_sexualfitness',
	[SQScorePillar.connectionBuilding]: 'fill_score_connection',
	[SQScorePillar.mentalResilience]: 'fill_score_resilience',
	[SQScorePillar.stimulationResponse]: 'fill_score_stimulation',
} satisfies Record<SQScorePillar, keyof UIThemeColors>;

export const sqScoreGetPillarColor = (pillar: SQScorePillar, theme: UITheme) =>
	sqScorePillarColors[theme][pillar];

const sqScorePillarColors = {
	[UITheme.dark]: {
		[SQScorePillar.sexualFitness]:
			themeColors[UITheme.dark][
				sqScoreThemeColors[SQScorePillar.sexualFitness]
			],
		[SQScorePillar.connectionBuilding]:
			themeColors[UITheme.dark][
				sqScoreThemeColors[SQScorePillar.connectionBuilding]
			],
		[SQScorePillar.mentalResilience]:
			themeColors[UITheme.dark][
				sqScoreThemeColors[SQScorePillar.mentalResilience]
			],
		[SQScorePillar.stimulationResponse]:
			themeColors[UITheme.dark][
				sqScoreThemeColors[SQScorePillar.stimulationResponse]
			],
	},
};

export const sqScoreOrderMap = sqScorePillarsOrdered.reduce(
	(acc, pillar, index) => {
		acc[pillar] = index;
		return acc;
	},
	{} as Record<SQScorePillar, number>,
);

export const sqScoreZero: SQPillarScore[] = sqScorePillarsOrdered.map(
	(pillar) => ({
		pillar,
		value: 0,
	}),
);

export const sqScoreMax: SQPillarScore[] = sqScorePillarsOrdered.map(
	(pillar) => ({
		pillar,
		value: sqScoreMaxPillarValue,
	}),
);

export const sqScoreHalf: SQPillarScore[] = sqScorePillarsOrdered.map(
	(pillar) => ({
		pillar,
		value: sqScoreMaxPillarValue / 2,
	}),
);

const isValidSQScorePillar = (value: string): value is SQScorePillar => {
	return Object.values(SQScorePillar).includes(value as SQScorePillar);
};

export const stringToSQScorePillar = (
	value: string,
): SQScorePillar | undefined => {
	if (isValidSQScorePillar(value)) return value;
	return undefined;
};

export const pillarImageVariants: Record<SQScorePillar, ImageSourcePropType> = {
	mental_resilience: require('../assets/mental-resilience.png'),
	sexual_fitness: require('../assets/sexual-fitness.png'),
	connection_building: require('../assets/build-connections.png'),
	stimulation_response: require('../assets/stimulation-control.png'),
};

/**
 * Generates an array of segments for use in a SegmentedProgress component.
 *
 * @param {SQPillarScore[]} pillarsScores - An array of SQ pillar scores.
 * @param {UITheme} theme - The current UI theme.
 * @returns {SegmentedProgressProps['segments']} An array of segment objects, each containing a color and progress value.
 *
 * This function:
 * 1. Uses the current UI theme to determine segment colors.
 * 2. Sorts the input scores based on a predefined pillar order.
 * 3. Maps each score to a segment object with:
 *    - A color based on the pillar type and current theme.
 *    - A progress value calculated as a ratio of the pillar's score to the maximum possible score.
 * 4. Returns an empty array if the input is not a valid array.
 */
export const generatePillarScoreSegments = (
	pillarsScores: SQPillarScore[],
	theme: UITheme,
): SegmentedProgressProps['segments'] => {
	return Array.isArray(pillarsScores)
		? [...pillarsScores]
				.sort((a, b) => sqScoreOrderMap[a.pillar] - sqScoreOrderMap[b.pillar])
				.map((p) => ({
					color: sqScoreGetPillarColor(p.pillar, theme),
					progress: p.value / sqScoreMaxPillarValue || INITIAL_DOT_PROGRESS,
				}))
		: [];
};
