import { InlineContentWrapperType } from '@wearemojo/sanity-schema';
import { ImageProps } from 'expo-image';
import { useContext } from 'react';
import { View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import AccountAvatar from '../AccountAvatar';
import CMSContext from '../cms/CMSContext';
import Text from '../Text';

type ItoAIMessageProps = {
	messages: string[] | InlineContentWrapperType[];
	avatarUrl: ImageProps['source'];
	hideAvatar?: boolean;
	textVariant?: 'body_lg' | 'ito_message';
	avatarSize?: 'small' | 'medium' | 'large' | 'flow_medium';
	therapistName?: string;
};

const ItoAIMessage = ({
	messages,
	avatarUrl,
	hideAvatar = false,
	textVariant = 'body_lg',
	avatarSize = 'small',
	therapistName,
}: ItoAIMessageProps) => {
	const { styles } = useStyles(stylesheet);
	const { contentVariables } = useContext(CMSContext);

	return (
		<View style={styles.messageContainer}>
			{messages.map((message, index) => {
				const showAvatar = !hideAvatar && index === 0;
				return (
					<View
						key={index}
						style={[styles.message, !showAvatar && styles.textMargin]}
					>
						{showAvatar && (
							<AccountAvatar
								size={avatarSize}
								avatarUrl={avatarUrl}
								noShimmer
							/>
						)}
						<View style={styles.textContainer}>
							<Text.CMSContent
								value={message}
								variant={textVariant}
								themeColor="content_neutral100"
								contentVariables={{ ...contentVariables, therapistName }}
							/>
						</View>
					</View>
				);
			})}
		</View>
	);
};

const stylesheet = createStyleSheet(({ spacing }) => ({
	messageContainer: {
		gap: spacing.xs,
	},
	message: {
		maxWidth: '80%',
		flexDirection: 'row',
		gap: spacing.md,
	},
	textContainer: {
		width: '100%',
	},
	textMargin: {
		marginLeft: spacing.xl4,
	},
}));

export default ItoAIMessage;
