import RoundArrowRightIcon from '@wearemojo/icons/Bold/Arrows/RoundArrowRightIcon';
import CheckCircleBoldIcon from '@wearemojo/icons/Bold/EssentionalUI/CheckCircleIcon';
import CheckCircleIcon from '@wearemojo/icons/Outline/EssentionalUI/CheckCircleIcon';
import { BrandColor } from '@wearemojo/ui-constants';
import { View } from 'react-native';
import Animated, { FadeInRight } from 'react-native-reanimated';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import GenericShadowCard from '../cards/GenericShadowCard';
import useUIContext from '../hooks/useUIContext';
import Text from '../Text';
import { toSentenceCase } from '../utils/textFormatting';

type WeekDay = {
	name: string;
	isChecked: boolean;
};

type RoutineComponentProps = {
	weekDays: WeekDay[];
	variant?: 'default' | 'withFooter';
	nextCheckInDate?: string;
	checkInText?: string;
};

const Day = ({ day, index }: { day: WeekDay; index: number }) => {
	const {
		styles,
		theme: { iconSize, colors },
	} = useStyles(stylesheet);
	const { isNative } = useUIContext();
	return (
		<Animated.View
			entering={isNative ? FadeInRight.delay(500 + index * 100) : undefined}
			key={day.name}
			style={styles.dayContainer}
		>
			{day.isChecked ? (
				<CheckCircleBoldIcon
					size={iconSize.sm}
					color={BrandColor.primary_yellow}
				/>
			) : (
				<CheckCircleIcon size={iconSize.sm} color={colors.content_neutral} />
			)}
			<Text variant="body_md" themeColor="content_neutral100">
				{toSentenceCase(day.name)}
			</Text>
		</Animated.View>
	);
};

export const RoutineComponent = ({
	weekDays,
	variant = 'default',
	nextCheckInDate,
	checkInText,
}: RoutineComponentProps) => {
	const {
		styles,
		theme: { iconSize },
	} = useStyles(stylesheet);
	const { isNative } = useUIContext();

	return (
		<GenericShadowCard style={styles.card}>
			<Text
				variant="heading_xxs_upper"
				themeColor="content_neutral"
				style={styles.title}
			>
				Your routine
			</Text>
			<View style={styles.linebreak} />
			<View style={styles.daysContainer}>
				{weekDays.map((day, index) => (
					<Day key={day.name} day={day} index={index} />
				))}
			</View>
			{variant === 'withFooter' && nextCheckInDate && (
				<View style={styles.footer}>
					<View style={styles.linebreak} />
					<Animated.View
						entering={
							isNative
								? FadeInRight.delay(500 + weekDays.length * 100)
								: undefined
						}
						style={styles.footerContent}
					>
						<RoundArrowRightIcon
							size={iconSize.sm}
							color={BrandColor.primary_yellow_lighter}
						/>
						<View>
							<Text variant="body_md" themeColor="content_neutral100">
								{nextCheckInDate}
							</Text>
							<Text variant="body_sm" themeColor="content_neutral">
								{checkInText}
							</Text>
						</View>
					</Animated.View>
				</View>
			)}
		</GenericShadowCard>
	);
};

const stylesheet = createStyleSheet(({ spacing, colors }) => ({
	card: {
		padding: spacing.xl,
	},
	title: {
		marginBottom: spacing.md,
	},
	linebreak: {
		borderTopWidth: 1,
		borderColor: colors.background_secondary,
		marginBottom: spacing.lg,
	},
	daysContainer: {
		gap: spacing.md,
	},
	dayContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		gap: spacing.sm,
	},
	footer: {
		paddingTop: spacing.lg,
	},
	footerContent: {
		flexDirection: 'row',
		gap: spacing.sm,
		alignItems: 'center',
	},
}));
