import { BrandColor } from '@wearemojo/ui-constants';
import { PropsWithChildren, useMemo } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

type Props = {
	style?: StyleProp<ViewStyle>;
} & PropsWithChildren;

const GenericShadowCard = ({ children, style }: Props) => {
	const { styles } = useStyles(stylesheet);

	const viewStyle = useMemo(
		() => StyleSheet.flatten([styles.container, style]),
		[styles.container, style],
	);

	return <View style={viewStyle}>{children}</View>;
};

const stylesheet = createStyleSheet(({ colors, radius }) => ({
	container: {
		backgroundColor: colors.background_level_1,
		borderTopColor: `${BrandColor.white}0D`,
		borderTopWidth: 1,
		borderRadius: radius.md,
		shadowColor: BrandColor.black,
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.2,
		shadowRadius: 5,
		elevation: 5,
	},
}));

export default GenericShadowCard;
